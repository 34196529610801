.pic_box{
    position: relative;
    width: 300px;
    height: 250px;
}
.pic_box span {
    display: block;
    width: 300px;
    height: 250px;
}
.picMark{
    width: 28px;
    height: 17px;
    overflow: hidden;
    position: absolute;
    left: 1px;
    bottom: 1px;
    z-index: 2;
    opacity: .8;
    transform: scale(.8);
}
