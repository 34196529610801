.tab_content {
    width: 100%;
    border-bottom: 1px solid rgba(245, 67, 67, 0.3);
    height: 39px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    & a {
        color: inherit;
    }
}
.tab_item {
    font-size: 18px;
    font-weight: 500;
    margin-right: 43px;
    line-height: 35px;
    color: #84848a;
    padding: 9px 0px;
    cursor: pointer;
}
.tab_item_small {
    margin-right: 34px;
}
.active {
    font-size: 20px;
    padding-bottom: 5px;
    color: #212223;
    border-bottom: 4px solid #f54343;
}
.normal:hover,
.more:hover {
    color: #f54343;
}
.more {
    float: right;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
    line-height: 42px;
    & img {
        width: 8px;
        vertical-align: -5px;
        margin-left: 5px;
    }
}
