.title {
    background: url(./title.png) no-repeat left bottom;
    padding-bottom: 8px;
    overflow: hidden;
    & h3 {
        height: 32px;
        line-height: 32px;
        font-family: 'microsoft yahei';
        font-style: normal;
        font-size: 24px;
        font-weight: normal;
        float: left;
    }
}

.list_box {
    position: relative;
    max-height: 1455px;
    width: 640px;
    border-bottom: 2px solid #eeeeee;
    overflow: auto;
    overflow-x: hidden;
}

.no_more {
    font-size: 14px;
    color: #999999;
    text-align: center;
    padding: 20px 0 25px;
}

.soft_ad {
    width: 640px;
    height: 111px;
    line-height: 111px;
    text-align: center;
}

.hard_ad {
    width: 640px;
    height: 123px;
    line-height: 123px;
    text-align: center;
}
.reportLink {
    font-size: 12px;
    color: #9e9e9e !important;
    height: 32px;
    line-height: 32px;
    background: url(https://x0.ifengimg.com/ucms/2022_20/E93B549B081053032692278CFC4D2B196E655E12_size1_w28_h28.png)
        no-repeat left center;
    background-size: 12px 12px;
    padding-left: 15px;
    float: right;
    text-decoration: none;
}
.reportLink:hover {
    font-size: 12px;
    color: #f54343 !important;
    height: 32px;
    line-height: 32px;
    background: url(https://x0.ifengimg.com/ucms/2022_20/5D5BADFED6BFC5E84E6A2AAF737C442BA098BD0A_size1_w28_h28.png)
        no-repeat left center;
    background-size: 12px 12px;
    text-decoration: none;
    padding-left: 15px;
    float: right;
}
