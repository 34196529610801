.box {
    position: relative;
    & ul {
        width: 680px;
        background: url(./lineBg.gif) repeat-x bottom left;
        & li {
            float: left;
            width: 300px;
            height: 56px;
            margin-right: 40px;
            padding: 12px 0;
            overflow: hidden;
            & a span {
                float: left;
                width: 100px;
                height: 56px;
                margin-right: 15px;
                transition: all 0.3s;
            }
            & a p {
                font: 14px/24px simsun;
                color: #222222;
            }
            &:hover {
                background: #f6f6f6;
                & a {
                    text-decoration: none;
                    & span {
                        transform: scale(1.1);
                    }
                    & p {
                        background: #f6f6f6;
                        color: #f54343;
                    }
                }
            }
        }
    }
}

.title {
    margin-bottom: 12px;
}

.title h3 {
    line-height: 32px;
    height: 32px;
    position: relative;
    font-family: 'microsoft yahei';
    border-bottom: 1px solid #dbdbdb;
}

.title h3 i {
    position: absolute;
    left: 0;
    top: 0;
    border-bottom: 1px solid #f54343;
    font-style: normal;
    font-size: 20px;
}
