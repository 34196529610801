.leftContent {
    float: left;
    width: 640px;
}

.space30 {
    clear: both;
    height: 30px;
    line-height: 0;
    font-size: 0;
}

.space40 {
    clear: both;
    height: 40px;
    line-height: 0;
    font-size: 0;
    width: 100%;
}

.hardAd {
    width: 640px;
}

.serviceAd {
    width: 640px;
}

.contentBottomAd {
    width: 640px;
}

.space25 {
    clear: both;
    height: 25px;
    line-height: 0;
    font-size: 0;
}
