.text p {
    text-indent: 28px;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 20px;
    text-align: justify;
    word-break: break-all;
    & img {
        margin-left: -28px;
        display: block;
        margin: 0px auto;
        max-width: 100%;
        height: auto;
    }
}

.text ul {
    list-style: none !important;
}
