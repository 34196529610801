.header {
    width: 980px;
    height: 26px;
    margin: 0 auto;
    padding: 7px 10px 3px;
    font-family: '宋体';
    position: relative;
    z-index: 999;
    background: #fff;
}

.user {
    width: 140px;
    float: right;
    text-align: right;
}

.topAd_box {
    width: 1000px;
    margin: 0 auto;
}

.topAd {
    width: 1000px;
}

.search_bar {
    width: 1000px;
    margin: 0 auto;
    height: 36px;
    padding: 20px 0 0 0px;
    position: relative;
    z-index: 103;
}

.logoAd {
    width: 120px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    position: absolute;
    right: 361px;
    top: 19px;
}
