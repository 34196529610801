.box {
    position: relative;
    height: 181px;
    background: #f6f6f6;
}

.title_box {
    height: 15px;
    margin: 0 auto;
    padding: 20px 0 12px;
}

.pic_box {
    width: 122px;
    height: 115px;
    margin: 0 auto;
}
