.hotList {
    position: relative;
    width: 300px;
    overflow: hidden;
    & ul {
        padding-top: 5px;
        & li {
            position: relative;
            padding: 12px 0;
            border-bottom: 1px solid #eeeeee;
            & a {
                display: block;
                & span {
                    float: left;
                    display: block;
                    margin-right: 15px;
                    width: 100px;
                    height: 56px;
                    transition: all 0.3s;
                }
            }
            & p {
                font: 14px/24px simsun;
                color: #222222;
            }
        }
        & li:hover {
            background: #f6f6f6;
            & a {
                color: #f54343;
                text-decoration: none;
                & span {
                    transform: scale(1.1);
                }
                & .play_btn {
                    background: url(./play1.png) no-repeat 0 0;
                }
            }
            & p {
                color: #f54343;
            }
        }
    }
}

.play_btn {
    background: url(./play0.png) no-repeat 0 0;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 33px;
    top: 28px;
    z-index: 100;
}

.title {
    min-height: 60px;
    overflow: hidden;
}
