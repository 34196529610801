.info_box {
    composes: clearfix from global;
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid #e7e7e7;
    &:hover {
        background: #f6f6f6;
        & a {
            color: #f54343;
        }
        & .comment > a {
            background: url(./comment_hover.png) left center no-repeat;
            text-decoration: none;
        }
    }
}

.pic {
    width: 144px;
    height: 80px;
    float: left;
    overflow: hidden;
    margin-right: 20px;
    position: relative;
    & span {
        display: block;
        width: 144px;
        height: 80px;
        transition: all 0.3s;
    }
    & span:hover {
        transform: scale(1.1);
    }
}

.v_logo {
    background: url(./play0.png) no-repeat;
    width: 32px;
    height: 32px;
    overflow: hidden;
    position: absolute;
    left: 56px;
    top: 24px;
}

.text {
    height: 50px;
    line-height: 22px;
    margin-bottom: 16px;
    overflow: hidden;
    & a {
        color: #222222;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        height: 26px;
        overflow: hidden;
        text-decoration: none;
    }
}

.time {
    float: left;
    & a {
        font-size: 12px;
        color: #999999;
        margin-right: 20px;
        text-decoration: none;
    }
}

.comment {
    float: right;
    color: #999999;
    line-height: 16px;
    & a {
        display: block;
        background: url(./comment.jpg) left center no-repeat;
        color: #999999;
        padding-left: 22px;
        font-size: 12px;
        height: 16px;
    }
}
