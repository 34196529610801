.artical {
    clear: both;
    width: 640px;
}

.topic_b {
    margin-top: 20px;
}

.artical_line {
    height: 5px;
    overflow: hidden;
    clear: both;
}

.vote_box {
    composes: clearfix from global;
    position: relative;
    margin-top: 20px;
}

.copyrightInfo {
    color: #999;
    float: right;
}

.author {
    float: left;
}

.share_box {
    float: right;
    margin-top: 22px;
}

.comment_b {
    margin-top: 20px;
}
.copyRight {
    background: #f8f8fb;
    padding: 12px 10px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    line-height: 20px;
    color: #5c5c5c;
    margin-top: 150px;
}
.copyRightCh {
    text-align: justify;
}
.copyRightZh {
    text-align: justify;
}
.articleBottomAd{
    margin-top: 40px;
}