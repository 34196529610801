.select_share {
    background: #fff;
    border: 1px #ddd solid;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    z-index: 103;
}

.header {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    margin: 0px auto;
    color: rgb(131, 127, 127);
    border-bottom: 1px #ddd solid;
    margin-bottom: 5px;
}
