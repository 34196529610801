.topic {
    font: bold 24px Microsoft Yahei;
    padding: 0 0 20px 0;
    letter-spacing: -1.5px;
    color: #222222;
}

.info {
    composes: clearfix from global;
    padding-bottom: 5px;
}

.time {
    float: left;
    color: #999;
    line-height: 22px;
}
.follow {
    float: left;
    width: 50px;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 6px;
    position: relative;
}
.followPicBtn {
    width: 50px;
    height: 20px;
    vertical-align: top;
    cursor: pointer;
    display: block;
}
.followPic {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 20px auto 0;
    background: #000;
    display: block;
}
.followBox {
    display: none;
    position: absolute;
    width: 124px;
    height: 174px;
    background: #fff url(https://x2.ifengimg.com/fe/shank/content/qrCodeWrapper.6619d3cf.png) no-repeat;
    top: 100%;
    margin-left: -36px;
    z-index: 999999;
    margin-top: 3px;
}
.follow:hover .followBox {
    display: block;
}
.followText {
    display: block;
    margin-top: 10px;
    text-align: center;
    color: #222222;
    font-size: 12px;
}

.publisher {
    vertical-align: middle;
}

.source > span,
.source > a {
    color: #808080;
    vertical-align: middle;
}

.source > a:hover {
    cursor: pointer;
    color: #f54343;
    text-decoration: none;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 31px;
    height: 13px;
    background: url(./dfh.png) no-repeat;
}

.share_box {
    z-index: 1000;
    float: right;
    padding-top: 16px;
    position: relative;
    width: 150px;
    cursor: pointer;
    overflow: visible;
}

.comment_box {
    display: block;
    float: right;
}

.comment {
    float: right;
    float: right;
    padding-top: 22px;
    font-weight: normal;
    text-align: right;
    color: #999;
}

.joinNum {
    color: #f54343 !important;
    font-weight: bold;
}

.commentNum {
    color: #f54343 !important;
    font-weight: bold;
}

.num {
    font-family: 'Georgia';
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
}

.text {
    height: 13px;
    margin-right: 10px;
    color: #999;
    font-weight: lighter;
}

.joinNum:hover,
.commentNum:hover {
    cursor: pointer;
    color: #f54343;
    & span {
        color: #f54343;
    }
}

.devide {
    border-top: 1px solid #ddd;
    height: 9px;
    overflow: hidden;
    clear: both;
    width: 100%;
}
.infoboxLink {
    float: left;
    display: block;
}
.infoboxLink .face {
    width: 40px;
    height: 40px;
    vertical-align: top;
    position: relative;
    float: left;
    margin-right: 10px;
}
.infoboxLink .faceImg {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.infoboxLink .faceV {
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 0;
    right: 0;
}
.infoboxLink .textTitle {
    float: left;
}
.infoboxLink .sourceTitle {
    height: 20px;
}
.sourceTitleText {
    color: #212223;
    font-family: PingFangSC-Medium;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #212223;
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
}
.sourceTitleText a {
    color: #212223;
    text-decoration: none;
}
.infoboxLink .timeBref {
    font-size: 12px;
    color: #999999;
    margin-top: 3px;
    max-width: 256px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.infoboxLink .timeBref a {
    color: #999999;
    text-decoration: none;
}
