.vote {
    border: solid 1px #f54343;
    padding: 6px 36px;
    padding-right: 10px;
    color: #f54343;
    float: left;
    background: url(./vote.png) no-repeat 12px 6px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.voted {
    border: solid 1px #f54343;
    padding: 6px 36px;
    color: #ffffff;
    float: left;
    background: url(./voted.png) no-repeat 12px 6px #f54343;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.vote1 {
    border: solid 1px #9e9e9e;
    padding: 6px 36px;
    padding-right: 10px;
    color: #212223;
    float: left;
    background: url(https://x0.ifengimg.com/ucms/2022_27/72B0A73CC8F312DFB67ACCC78FFA4C6DE166C9AE_size1_w17_h16.png)
        no-repeat 12px 6px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.voted1 {
    border: solid 1px #9e9e9e;
    padding: 6px 36px;
    color: #212223;
    float: left;
    background: url(https://x0.ifengimg.com/ucms/2022_27/0833E67ABDCDECB7342A5E913122D43FD6F5EBF8_size1_w17_h16.png)
        no-repeat 12px 6px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.vote2 {
    border: solid 1px #9e9e9e;
    padding: 6px 36px;
    padding-right: 10px;
    color: #212223;
    float: left;
    background: url(https://x0.ifengimg.com/ucms/2022_27/263BDFFD792F8F010495A3CA11724D75821383DC_size1_w17_h16.png)
        no-repeat 12px 6px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.voted2 {
    border: solid 1px #9e9e9e;
    padding: 6px 36px;
    color: #212223;
    float: left;
    background: url(https://x0.ifengimg.com/ucms/2022_27/E1C708D0722EC66102D51E5C1C18C77856ADB465_size1_w17_h16.png)
        no-repeat 12px 6px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.greyVote {
    border: solid 1px #999;
    padding: 6px 10px;
    padding-right: 10px;
    color: #333;
    float: left;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}

.greyVoted {
    border: solid 1px #eee;
    background: #d3d3d3;
    padding: 6px 10px;
    color: #ffffff;
    float: left;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 22px;
}
