.box {
    position: relative;
    width: 300px;
    overflow: hidden;
    & ul {
        padding-top: 5px;
        & li {
            position: relative;
            border-bottom: 1px solid #eeeeee;
            padding: 12px 0;
            & a {
                display: block;
                background: #ffffff;
                min-height: 42px;
                & span {
                    float: left;
                    width: 100px;
                    height: 56px;
                    margin-right: 15px;
                    transition: all 0.3s;
                }
                & p {
                    font: 14px/24px simsun;
                    color: #222222;
                    overflow: hidden;
                }
            }
            &:hover {
                background: #f6f6f6;
                & a {
                    background: #f6f6f6;
                    color: #f54343;
                    text-decoration: none;
                    & span {
                        transform: scale(1.1);
                    }
                    & p {
                        color: #f54343;
                    }
                }
            }
        }
    }
}
