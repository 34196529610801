.articleCutoff {
    position: relative;
    cursor: pointer;
}

.articleShadow {
    position: absolute;
    top: -111px;
    left: 0;
    width: 100%;
    height: 111px;
    overflow: hidden;
    background: url(https://x0.ifengimg.com/ucms/2024_20/29BC5F9A1702E84BAA15B91AC3A3ED33743DE0B8_size12_w644_h111.png) no-repeat 0 0;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.articleShadowText {
    margin-top: 84px;
    color: #757575;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    padding: 0 32px;
    position: relative;
}

.articleShadowText::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 30px;
    background: url(https://x0.ifengimg.com/ucms/2024_20/9D8057D01C923C831AB03A7507250519C5717B0A_size0_w48_h2.png) no-repeat left center;
    background-size: 24px auto;
}

.articleShadowText::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 30px;
    background: url(https://x0.ifengimg.com/ucms/2024_20/6513F55ED07FB3F2C03D346CD2DB254F1C560253_size0_w48_h2.png) no-repeat right center;
    background-size: 24px auto;
}

.articleShadowTextRed {
    color: #f54343;
    padding: 0 9px;
}

.qrCodeFix {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999999;
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
}

.qrCodeFixBox {
    width: 404px;
    height: 383px;
    position: relative;
    background: url(https://x0.ifengimg.com/ucms/2024_20/84065080C150F8ECBAEDAE2606C319BEC1320A30_size86_w808_h766.png) no-repeat 0 0;
    background-size: cover;
}

.qrCodeClose {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 23px;
    right: 18px;
    background: url(https://x0.ifengimg.com/ucms/2024_20/8B853FDA8022330EB568AB9D784707A9F9001DE1_size1_w28_h28.png) no-repeat 0 0;
    background-size: cover;
    cursor: pointer;
}

.qrCodeFixTitle {
    color: #712e08;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 20px;
    line-height: 28px;
    font-style: normal;
    font-weight: 600;
    padding-top: 48px;
    text-align: center;
}

.qrCodeFixImage {
    width: 168px;
    height: 179px;
    margin: 21px auto 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 #705d181a;
}

.qrCodeFixPic {
    width: 138px;
    height: 138px;
    vertical-align: top;
    margin: 15px 15px 0 15px;
}

.qrCodeFixImageTip {
    color: #323c57;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 16px;
    text-indent: 16px;
}

.qrCodeFixInfo {
    margin-top: 21px;
    color: #343e5d;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.articleCutoffTip {
    width: 640px;
    height: 240px;
    background: url(https://x0.ifengimg.com/ucms/2024_28/01AAC0AC4B39FE8232AD7FC750311A8DFBB0E543_size95_w646_h240.png) no-repeat 0 0;
    background-size: 640px 240px;
    position: relative;
}

.articleCutoffQrcode {
    width: 80px;
    height: 80px;
    border: 0.5px solid #BF8752;
    background: #FFF6E8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 162px;
    left: 120px;
}

.articleCutoffQrcodePic {
    width: 68px;
    height: 68px;
}

.qrCodeTextRed {
    color: #ff0000;
    font-weight: bold;
}