.video_box {
    width: 620px;
    margin-bottom: 20px;
    padding-bottom: 19px;
    background: url(./line.gif) repeat-x bottom;
    overflow: hidden;
    & > input {
        vertical-align: middle;
    }
    & > span {
        padding-left: 5px;
        font-size: 12px;
        color: #666;
    }
}

.video {
    width: 480px;
    height: 380px;
    margin: 0 auto;
    background: url(./load.gif) no-repeat center center;
}
:global {
    & video {
        max-width: 100%;
    }
}
