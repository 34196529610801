.box {
    position: relative;
}

.box_up {
    composes: clearfix from global;
    padding: 16px 0;
    background: #f6f6f6;
}

.box_down {
    composes: clearfix from global;
    margin: 40px 0 0 0;
    padding: 16px 0;
    background: #f6f6f6;
}

.pic {
    width: 148px;
    border-right: 1px solid #e3e3e3;
    text-align: center;
    float: left;
    & img {
        vertical-align: bottom;
        width: 80px;
        height: 80px;
    }
    & .text {
        line-height: 18px;
        color: #666;
        padding-top: 8px;
    }
}

.detail {
    float: right;
    width: 135px;
    margin-top: 13px;
    & p {
        font: bold 14px/18px simsun;
        float: left;
        margin-bottom: 35px;
        background: url(./v.gif) no-repeat right 5px;
        padding-right: 15px;
        color: #01426c;
    }
}
