.logo {
    float: left;
    font-family: Microsoft Yahei;
    font-size: 14px;
    height: 34px;
    overflow: hidden;
    & img {
        position: relative;
        width: 161px;
        height: 27px;
        left: 0;
        right: 361px;
        top: 0;
        float: left;
        vertical-align: bottom;
    }
}

.breadcrumbs {
    float: left;
    padding: 7px 0 0 15px;
    font-weight: bold;
    & a {
        cursor: pointer;
        color: #2b2b2b !important;
    }
    & a:hover {
        color: #f54343 !important;
    }
}
