.box_L {
    z-index: 2147483645;
    position: fixed;
    float: left;
    display: block;
    top: 100px;
    left: 1px;
    width: 120px;
    height: 315px;
}

.box_R {
    z-index: 2147483645;
    position: fixed;
    display: block;
    top: 100px;
    right: 1px;
    width: 120px;
    height: 315px;
}

.bottom_affix {
    position: fixed;
    left: 50%;
    bottom: 112px;
    margin-left: 520px;
    width: 50px;
    height: 150px;
    z-index: 100;
}

.backToHome {
    display: block;
    width: 50px;
    height: 50px;
    background: url(./sidebar.gif) no-repeat 0px 0px;
    margin-bottom: 1px;
}

.backToHome:hover {
    background: url(./sidebar.gif) no-repeat -51px 0px;
}

.comment {
    cursor: pointer;
    display: block;
    width: 50px;
    height: 50px;
    background: url(./sidebar.gif) no-repeat 0 -51px;
    margin-bottom: 1px;
}

.comment:hover {
    background: url(./sidebar.gif) no-repeat -51px -51px;
}

.feedback {
    display: block;
    width: 50px;
    height: 50px;
    background: url(./sidebar.gif) no-repeat 0 -102px;
    margin-bottom: 1px;
}

.feedback:hover {
    background: url(./sidebar.gif) no-repeat -51px -102px;
}

.backToTop {
    cursor: pointer;
    display: block;
    width: 50px;
    height: 50px;
    background: url(./sidebar.gif) no-repeat 0 -153px;
}

.backToTop:hover {
    background: url(./sidebar.gif) no-repeat 0px -153px;
}

.floatAd {
    display: none;
}
