.page_list {
    composes: clearfix from global;
    font-size: 12px;
    margin: 0 auto 13px;
    & a {
        cursor: pointer;
        padding: 3px 6px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
        color: #2b2b2b;
        font-size: 14px;
        margin: 2px 4px 0 1px;
        float: left;
        line-height: 14px;
    }
}

.current {
    background: none repeat scroll 0 0 #f54343;
    color: #fff !important;
    cursor: auto;
    font-weight: bold !important;
    padding: 2px 6px !important;
    text-decoration: none;
}
