.right_article {
    width: 100%;
    margin-top: 10px;
    & .first_item {
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 169px;
        display: inline-block;
        & img {
            width: 100%;
        }
        & p {
            position: absolute;
            overflow: hidden;
            background: #000;
            background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.7)));
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
            text-overflow: ellipsis;
            width: 280px;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            height: 22px;
            white-space: nowrap;
            padding: 10px;
            bottom: 0px;
            left: 0px;
        }
    }
    & .item {
        display: block;
        cursor: pointer;
        color: #212223;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }
    & .item:hover {
        text-decoration: none;
        color: #f54343;
    }
    & .check_more {
        width: 100%;
        margin-top: 10px;
        display: block;
        cursor: pointer;
        height: 36px;
        background-color: #f0f0f0;
        text-align: center;
        & span {
            font-size: 14px;
            color: #84848a;
            line-height: 36px;
        }
    }
    & .check_more:hover {
        text-decoration: none;
        & span {
            color: #f54343;
        }
    }
}
.assess {
    width: 100%;
    margin-top: 20px;
}
