.bd {
    display: none;
}

.space40 {
    clear: both;
    height: 40px;
    line-height: 0;
    font-size: 0;
    width: 100%;
    border-top: 1px solid #cdcdcd;
}

.space10 {
    clear: both;
    height: 10px;
    line-height: 0;
    overflow: hidden;
    font-size: 0;
}

:global {
    & .textAlignCenter{
        text-align: center !important;
    }
}
