.wireless {
    width: 300px;
    overflow: hidden;
}

.link {
    float: right;
    width: 126px;
    display: block;
    padding-top: 8px;
    color: #666;
    & a {
        font-family: Microsoft Yahei;
        font-size: 14px;
        color: #222222 !important;
        font-weight: bold;
        padding-right: 3px;
    }
    & a:hover {
        color: #f54343;
        text-decoration: none;
    }
}

.list {
    composes: clearfix from global;
    width: 320px;
    & li {
        float: left;
        width: 140px;
        padding: 10px 20px 0 0;
        & a {
            width: 140px;
            overflow: hidden;
            display: block;
            padding-bottom: 10px;
            & span {
                display: block;
                width: 140px;
                height: 80px;
                transition: all 0.3s;
            }
        }
        & a:hover {
            color: #f54343;
            & span {
                transform: scale(1.1);
            }
        }
        & p {
            line-height: 20px;
            padding-top: 3px;
            text-align: left;
            & a {
                font-size: 14px;
                color: #222222;
                width: 140px;
                overflow: hidden;
                display: block;
                padding-bottom: 10px;
            }
            & a:hover {
                color: #f54343;
            }
        }
    }
}
