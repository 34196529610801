.timeLine {
    display: block;
    background: #f5f5f5;
    padding: 0 10px 10px;
    margin: 10px 0;
}
.feedInfo {
    display: block;
    overflow: hidden;
}
.infoText {
    float: left;
    color: #9e9e9e;
    font-size: 12px;
    line-height: 32px;
}
.infoStatus {
    float: right;
    color: #9e9e9e;
    font-size: 12px;
    line-height: 32px;
}
.timeLineFeed {
    overflow: hidden;
}
.feedPic {
    width: 140px;
    height: 80px;
    border-radius: 4px;
    float: left;
    margin-right: 10px;
}
.feedText {
    float: left;
    width: 470px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.feedTitle {
    font-size: 16px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #212223;
}
.feedBref {
    font-size: 14px;
    line-height: 20px;
    color: #9e9e9e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
