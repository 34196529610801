.title {
    background: url(./title_bg.png) no-repeat left bottom;
    position: relative;
    padding-top: 9px;
    padding-bottom: 10px;
    margin-bottom: 3px;
    & h4 {
        font-size: 20px;
        line-height: 22px;
        font-family: Microsoft Yahei;
        font-weight: bold;
        color: #2b2b2b;
        position: relative;
        width: 150px;
    }
    & a {
        font-size: 20px;
        line-height: 22px;
        font-family: Microsoft Yahei;
        font-weight: bold;
        color: #2b2b2b;
        position: relative;
        width: 150px;
        &:hover {
            color: #f54343;
            text-decoration: none;
        }
    }
    & .imgMark{
        position: absolute;
        right: 0;
        top: 17px;
        width: 24px;
    }
}
